<template>
  <div style="height: 100%">
    <el-container style="height: 100%">
      <!-- / 左侧菜单 -->
      <el-aside style="width: auto !important">
        <frAside></frAside>
      </el-aside>
      <el-container>
        <!-- / 头部标题 -->
        <el-header></el-header>

        <el-main class="fr_main_div">
          <el-row :gutter="35">
            <el-col :lg="15" :sm="24">
              <div class="doc_title">{{ tuToria.tls_title }}</div>
              <div class="list_h">内容说明</div>
              <div class="doc_center" >
                <div class="doc_font_color" v-html="tuToria.tls_center"></div>
              </div>
            </el-col>
            <el-col :lg="9" :sm="24">
              <div class="problem_div">
                <div class="problem_title">最新列表</div>
                <div class="problem_user">
                  <div class="" style="display: flex">
                    <div class="problem_user_about">
                      <el-image
                        class="fengrui_img"
                        :src="
                          require('../../assets/imgs/doc/problem_list_b.svg')
                        "
                        fit="cover"
                      ></el-image>
                    </div>
                    <div class="problem_user_emi">
                      <el-image
                        class="fengrui_img"
                        :src="
                          require('../../assets/imgs/doc/problem_list_z.svg')
                        "
                        fit="cover"
                      ></el-image>
                    </div>
                  </div>
                  <div class="problem_user_img">
                    <div class="problem_user_online"></div>
                  </div>
                </div>

                <div class="" style="margin-top: 50px">
                  <div v-for="(item, index) in proBm" :key="index">
                    <div class="problem_list_questions">
                    <div class="problem_list_questions_img"></div>
                    <div class="problem_list_questions_center">
                      {{ item.pro_ask }}
                    </div>
                  </div>

                  <div class="master_list">
                    <div class="problem_list_questions_center master_list_bg">
                      {{ item.pro_asn }}
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { GetfrDoc } from "../../components/api";
import axios from "axios";
import aside from "../../components/aside.vue";
export default {
  components: {
    frAside: aside,
  },
  data() {
    return {
      tuToria: [],
      proBm: [],
      fengrui: "1111"
    };
  },
  mounted() {
    this.frdoc();
    // this.setMenPath();
  },

  methods: {
    frdoc() {
      axios
        .get(GetfrDoc)
        .then((res) => {
          this.tuToria = res.data.tutorials;
          this.proBm = res.data.problem;
        })
        .catch((error) => console.log(error));
    },
    setMenPath(){
      this.$store.commit('updateMenPath','1');
    }
  },
};
</script>
<style scoped>
/* 问题列表 */
.master_list_bg {
  background: #eef2fd !important;
}
.master_list {
  display: flex;
  justify-content: end;
  margin-bottom: 35px;
}
.problem_list_questions_center {
  padding: 14px;
  background: #fef2f3;
  border-radius: 11px;
  margin-left: 10px;
  max-width: 60%;
}
.problem_list_questions_img {
  width: 33px;
  height: 33px;
  background: #e0e0e0;
  border-radius: 50%;
  opacity: 1;
}
.problem_list_questions {
  display: flex;
  width: 100%;
  margin-bottom: 35px;
}
.problem_user_online {
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background: #5ae28e;
  border-radius: 50%;
}
.problem_user_img {
  position: relative;
  width: 32px;
  height: 32px;
  background: #ed7777;
  border-radius: 50%;
  right: 0;
}
.problem_user_emi {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  overflow: hidden;
}
.problem_user_about {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  margin-right: 10px;
  overflow: hidden;
}
.problem_user {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.problem_title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}
.problem_div {
  background-color: #ffffff;
  border-radius: 18px;
  padding: 24px;
}

/* 最新 */
.list_h {
  color: #111026;
  margin: 35px 0;
}

/* 文章内容 */
.doc_center {
  background: #ffffff;
  padding: 24px;
  border-radius: 18px;
  margin-bottom: 20px;
}
.doc_title {
  background: #9698d6;
  border-radius: 18px;
  color: #4d4cac;
  font-size: 40px;
  font-weight: bold;
  padding: 30px 66px;
}

/* macin主体内容 */
.fr_main_div .el-header {
  background: rgb(238, 240, 227);
}

.el-aside {
  background-color: #ffffff;
}
/* css3媒体查询 */
@media (max-width: 760px) {
  .doc_title{
    padding: 30px 30px;
  }
  .list_h{
    margin: 15px 0px;
  }
}
/* 深色模式 */
@media (prefers-color-scheme: dark) { 
  .el-main,.el-header,.el-drawer { 
    background-color: #1f2a38 !important;
  }
  .list_h,.list_title,.problem_title,.problem_list_questions_center,.doc_font_color{
    color: #ffffff !important;
  }
  .doc_center,.problem_div{
    background-color: #101927;
  }
  .problem_list_questions_center{
    color: #464646;
  }
  
}
</style>